import '../site/behaviors/layout/index.scss'
import '../site/behaviors/wrapper/index.scss'

import '../common/helpers/ie_fixes'
import '../common/components/admin_bar'
import '../common/components/videojs'
import '../common/components/cookie_consent'
import '../common/components/editor'
import '../common/components/site_form'
import '../common/behaviors/email_validation'
import '../common/behaviors/field_wrapper'
import '../common/behaviors/intl_phone'
import '../common/behaviors/timer'
import '../common/behaviors/embedded_order_form'
import '../common/behaviors/one_click_purchase'
import '../common/behaviors/scrolltop'

import '../site/behaviors/observable_fields'
import '../site/behaviors/forms'
import '../site/behaviors/button/index.scss'
import '../site/behaviors/checkbox_other'
import '../site/behaviors/collapsible_text'
import '../site/behaviors/collapsing_comments'
import '../site/behaviors/editor/draft_content'
import '../site/behaviors/empty_state/index.scss'
import '../site/behaviors/engagement_badges/index.scss'
import '../site/behaviors/gradient_placeholders/index.scss'
import '../site/behaviors/icons/index.scss'
import '../site/behaviors/info_entity/index.scss'
import '../site/behaviors/international_phone'
import '../site/behaviors/lightbox/index.scss'
import '../site/behaviors/modals'
import '../site/behaviors/placeholder/index.scss'
import '../site/behaviors/preview_notice'
import '../site/behaviors/product'
import '../common/behaviors/checkboxes_group'
import '../site/behaviors/self_liquidating_section/index'
import '../site/behaviors/section/index.scss'
import '../site/behaviors/sidebar/index.scss'
import '../site/behaviors/simple_loader/index.scss'
import '../site/behaviors/site_disabled/index.scss'
import '../site/behaviors/title_strip/index.scss'
import '../site/behaviors/videojs/index.scss'
import '../site/entrypoints'
import '../site/behaviors/remote'
import '../site/behaviors/text/index.scss'
import '../site/behaviors/truncating_content'
import '../site/behaviors/video_links'
import '../site/behaviors/links'
import '../site/behaviors/tooltip/index.scss'
import '../site/sections/simplero_new_support_response_time'
import '../site/sections/stealme'
import '../site/header_height_calculator'

/*
 We don't want most of @rails/ujs as our handlers in app/javascript/site/behaviors/remote/ handle remote forms, do we
 don't call Rails.start(). We do want remote link support, though. We have a patched version of this npm module as when
 using vite, Rails.start() is automatically called. https://github.com/ElMassimo/vite_ruby/issues/246
 */
import Rails from '@rails/ujs'

Rails.delegate(
  document,
  'a[data-method]:not([data-remote])',
  'click',
  Rails.handleMethod
)
